import React, { useEffect } from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import TheFATable from "../../components/thefa-table"

export default function TeamBoysU13() {
    
  return ( 
    <Layout>
    <section className="container">
    <h1>Under 13's</h1>
    <div className="row mb-2">
        <div className="col-md-8">
            <div className="row">
            <img src="/boys-u13.jpg" className="img-fluid mb-4 team-page-hero" />
            </div>
            <div className="row mt-4 mb-4">
            <p>
            We currently have a squad of 28 players of all mixed abilities playing in the Garforth League.
            </p>
            <p>
                All coaches are FA qualified. In additional all staff are CRC with at least one First Aid qualified member present at all times.
            </p>
            </div>

            <div className="row border thefa-table g-0 rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 ">
            <h3>League Table</h3>
            <TheFATable leagueId="489730632"></TheFATable>
            </div>

        </div>

        <div className="col-md-4">
            <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
            <div className="col p-4 d-flex flex-column position-static">
                <h3 className="lcj-color">Team Information</h3>
                <dl>
                <dt>Age Range</dt>
                <dd>School Year 8, DoB 01/09/2009 - 31/08/2010</dd>
                <dt>Manager(s)</dt>
                <dd>Phil Shaw</dd>
                <dt>Training</dt>
                <dd>Saturday mornings (email for details)</dd>
                <dt>League</dt>
                <dd>Garforth Junior League</dd>
                <dt>Join the team</dt>
                <dd><a href="mailto:philjshaw@msn.com">philjshaw@msn.com</a></dd>
                </dl>
            </div>
            </div>

            <div className="row">
            <div className="col">
                <div className="card shadow-sm">
                <div className="card-header">Team Sponsor</div>
                <img src="/sponsors-wanted.jpg" />
                <div className="card-body">
                    <p className="card-text">Interested in sponsoring a team? Click <Link to="/sponsors" title="Sponsors">here</Link> to find out more.</p>
                </div>
                </div>
            </div>    
            </div>
        </div>
    </div>
    </section>
    </Layout>
  );
}

